import React from "react"
import { useMediaQuery } from "react-responsive"

interface Props {
    children?: any;
}

export const Mobile = ({ children }: Props) => {
  const IsMobile = useMediaQuery({
    query: "(max-width:1000px)"
  });
  return <>{IsMobile && children}</>
}

export const Tablet = ({ children }: Props) => {
  const IsTablet = useMediaQuery({
    query: "(min-width:1000px)"
  });
  return <>{IsTablet && children}</>
}