import React from "react"
import { Mobile, Tablet } from "../../mode/modes";
import GlobalStyles from "../../style/GlobalStyles";
import GlobalStylesMobile from "../../style/GlobalStylesMobile";

//components 
import TabletHome from "./components/tablet";
import MobileHome from "./components/mobile";

export const HomIndex = () => {
  return (
    <>
      <Mobile>
        <GlobalStylesMobile/>
        <MobileHome/>
      </Mobile>
      <Tablet>
        <GlobalStyles/>
        <TabletHome/>
      </Tablet>
    </>
  );
}