export const colors = {
    On: '#FF0000',
    Off: '#01DF3A',

    Connect: '#01DF3A',
    NotConnect: '#FF0000',

    ControlTrueOn: '#81F781',
    ControlFalseOn: '#FA5858',
    ControlOff: '#BDBDBD',

    // Grey
    G1: '#f7f7f7',
    G2: '#CDCDCD',
    G3: '#8A8A8A',
    G4: '#535353',
    G5: '#222222',

    // Primary
    Primary1: '#C3DEFF',
    Primary3: '#1E80C4',
};