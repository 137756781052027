import { RosMessage } from './msg';
import { Time } from './built_in_msgs';

export class Header extends RosMessage {
  static readonly type: string = 'std_msgs/Header';

  stamp: Time = new Time(undefined);

  frame_id: string = '';
}

export class Bool extends RosMessage {
  static readonly type: string = 'std_msgs/Bool';

  data: boolean = false;
}

export class Float32 extends RosMessage {
  static readonly type: string = 'std_msgs/Float32';

  data: number = 0.0;
}

export class Int32 extends RosMessage {
  static readonly type: string = 'std_msgs/Int32';

  data: number = 0;
}
