import styled from "styled-components";

import { colors } from "../../global/colors";

export const DebugContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: 10px
`;

export const HeaderContainer = styled.div`
    height: 10vw;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const MainFlexContainer = styled.div`
    height: 60vw;
    display: flex;
`;

export const FooterContainer = styled.div`
    height: 30vw;
`;

export const LeftContainer = styled.div`
    width: 30vw;
`;

export const CenterContainer = styled.div`
    width: 40vw;
`;

export const RightContainer = styled.div`
    width: 30vw;
`;

export const RobotViewerControlContainer = styled.div`
    height: 100%;
`;

export const RobotStatusViewerContainer = styled.div`
    height: 50%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3,1fr);
`;

export const RobotControlContainer = styled.div`
    height: 50%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3,1fr);
`;

export const HighLevelStatusContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2,1fr);
`;

export const PauseButtonContainer = styled.div`
    width: 70%;
    position: relative;
    
    :after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

interface RobotBoolStatus {
    status: boolean;
}

// Header
export const Connected = styled.h2<RobotBoolStatus>`
    color: ${(props) => (props.status ? colors.Connect : colors.NotConnect)};
    font-size: 5vw;
`;

// Main
export const DemoRobotMap = styled.img`
    height: 100%;
`;

export const RobotBoolStatusViwer = styled.div<RobotBoolStatus>`
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    background-color: ${(props) => (props.status ? colors.On : colors.Off)};
    margin: auto;
    border: solid #000000 0.5vw;
`;

export const RobotNumberStatusViwer = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
`;

export const RobotStatusViewerName = styled.p`
    font-size: 3vw;
`;

export const RobotControlButtonLeft = styled.button<RobotBoolStatus>`
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
    font-size: 4vw;
    background-color: ${(props) => (!props.status ? colors.ControlFalseOn : colors.ControlOff)};
`;
export const RobotControlButtonRight = styled.button<RobotBoolStatus>`
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
    font-size: 4vw;
    background-color: ${(props) => (props.status ? colors.ControlTrueOn : colors.ControlOff)};
`;

export const PauseButton = styled.button<RobotBoolStatus>`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0;
    background-color: ${(props) => (props.status ? colors.On : colors.Off)};
    cursor: pointer;
    color: #000000;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
`;

export const LogBox = styled.div`
    width: 90%;
    height: 25vw;
    border: 1px solid black;
    overflow-y: auto;
    margin: auto;
`;

export const LogData = styled.p`
    font-size: 2vw;
`;