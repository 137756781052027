import { RosService } from "./srv";

export class SetBool extends RosService {
    static readonly type: string = 'std_srvs/SetBool';

    data: boolean = false;
}

export class Empty extends RosService {
    static readonly type: string = 'std_srvs/Empty';
}