import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
}

#root {
  width: 100vw;
  height: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}`;