import React, { useEffect, useState, useRef } from "react";

import {
    useRos,
    usePublisher, 
    useSubscription,
    useService,
    Bool,
    Float32,
    Int32,
    StringStamped,
    Logging,
    SetBool,
    Empty
} from "../../../ros";

import { 
    DebugContainer,
    HeaderContainer,
    MainFlexContainer,
    LeftContainer,
    CenterContainer,
    RightContainer,
    RobotViewerControlContainer,
    RobotStatusViewerContainer,
    HighLevelStatusContainer,
    DemoRobotMap,
    Connected,
    PauseButton,
    RobotBoolStatusViwer,
    RobotNumberStatusViwer,
    RobotStatusViewerName,
    RobotControlContainer,
    RobotControlButtonLeft,
    RobotControlButtonRight,
    LogBox
} from "../../../components/styled/home";
import Map from '../../../assets/image/map.png'

const TableteHome = () => {
    const [pauseName, setPauseName] = useState("CONTINUE");

    const MAX_SIZE = 100;
    const [recievedDataArr, setRecievedDataArr] = useState([""]);
    const [reportStateDataArr, setReportStateDataArr] = useState([""]);

    const rosState = useRos();

    // subscriber monitoring data 
    // robot status
    const [bumperData, setBumperData] = useState(false);
    const [emergencyData, setEmergencyData] = useState(false);
    const [batteryData, setBatteryData] = useState(0.0);
    const [safetyLanternData, setSafetyLanternData] = useState(false);
    // pause status
    const [pauseData, setPauseData] = useState(false);
    // high-level
    const [recievedData, setRecievedData] = useState("");
    const [reportStateData, setReportStateData] = useState("");

    // useSubscription
    useSubscription<Bool>('emergency', Bool.type, (msg) => {
        setEmergencyData(msg.data);
    });
    useSubscription<Bool>('uros/bumper', Bool.type, (msg) => {
        setBumperData(msg.data);
    });
    useSubscription<Float32>('battery/voltage', Float32.type, (msg) => {
        setBatteryData(msg.data);
    });
    useSubscription<Bool>('uros/safety_lantern', Bool.type, (msg) => {
        setSafetyLanternData(msg.data);
    });
    // pause status
    useSubscription<Bool>('pause', Bool.type, (msg) => {
        setPauseData(msg.data);
    }); 
    // high-level
    useSubscription<StringStamped>('iot/recieved', StringStamped.type, (msg) => {
        setRecievedData(msg.data);
    });
    useSubscription<StringStamped>('report_state', StringStamped.type, (msg) => {
        console.log(msg.data)
        setReportStateData(msg.data);
    });

    // publisher
    const msgSearchLight = usePublisher('search_light/light_cmd', Int32.type);
    const msgHeadLight = usePublisher('head_light/light_cmd', Int32.type);
    const msgSafetyLantern = usePublisher('uros/safety_lantern', Bool.type);

    // service
    const srvPause = useService('/pause', Empty.type);
    const srvUnPause = useService('/unpause', Empty.type);

    const sendTopicSearchLight = (data: number) => {
        const SearchLightTopicMsg: Int32 = { data };
        msgSearchLight.publish(SearchLightTopicMsg);
    };
    const sendTopicHeadLight = (data: number) => {
        const HeadLightTopicMsg: Int32 = { data };
        msgHeadLight.publish(HeadLightTopicMsg);
    };
    const sendTopicSafetyLantern = (data: boolean) => {
        const SafetyLanternTopicMsg: Bool = { data };
        msgSafetyLantern.publish(SafetyLanternTopicMsg);
    };

    useEffect(() => {
        let tempArr:any[] = [];
        if (recievedDataArr.length >= MAX_SIZE){
            tempArr = recievedDataArr.slice(0, -1);
        }
        else {
            tempArr = recievedDataArr;
        }
        setRecievedDataArr([...tempArr, recievedData]);
    },[recievedData]);

    useEffect(() => {
        let tempArr:any[] = [];
        if (reportStateDataArr.length >= MAX_SIZE){
            tempArr = reportStateDataArr.slice(0, -1);
        }
        else {
            tempArr = reportStateDataArr;
        }
        setReportStateDataArr([...tempArr, reportStateData]);
    },[reportStateData]);

    const pauseService = (isPaused: boolean) => {
        const request: Empty = {};
        if(isPaused){
            setPauseName('PAUSE');
            srvUnPause.callService(request, () => {});
        } else {
            setPauseName('CONTINUE');
            srvPause.callService(request, () => {});
        }
    }

    return(
        <DebugContainer>
            <HeaderContainer>
                <Connected status={rosState.isConnected}>connect</Connected>
            </HeaderContainer>
            <MainFlexContainer>
                <LeftContainer>
                    <DemoRobotMap src={Map} alt="map.png"/>
                </LeftContainer>
                <CenterContainer>
                    <RobotViewerControlContainer>
                        <RobotStatusViewerContainer>
                            <RobotStatusViewerName>emergency</RobotStatusViewerName>
                            <RobotBoolStatusViwer status={emergencyData}></RobotBoolStatusViwer>
                            <RobotStatusViewerName>bumper</RobotStatusViewerName>
                            <RobotBoolStatusViwer status={bumperData}></RobotBoolStatusViwer>
                            <RobotStatusViewerName>battery</RobotStatusViewerName>
                            <RobotNumberStatusViwer>{batteryData.toFixed(1)}V</RobotNumberStatusViwer>
                        </RobotStatusViewerContainer>
                        <RobotControlContainer>
                            <RobotStatusViewerName>safety lantern</RobotStatusViewerName>
                            <RobotControlButtonLeft status={safetyLanternData} onClick={() => sendTopicSafetyLantern(false)}>OFF</RobotControlButtonLeft>
                            <RobotControlButtonRight status={safetyLanternData} onClick={() => sendTopicSafetyLantern(true)}>ON</RobotControlButtonRight>
                        </RobotControlContainer>
                    </RobotViewerControlContainer>
                    <HighLevelStatusContainer>
                        <LogBox>{recievedDataArr.map((data, index) => {
                            return (<div key={index}>{data}</div>)
                        })}</LogBox>
                        <LogBox>{reportStateDataArr.map((data, index) => {
                            return (<div key={index}>{data}</div>)
                        })}</LogBox>
                        </HighLevelStatusContainer>
                </CenterContainer>
                <RightContainer>
                    <PauseButton status={pauseData} onClick={() => pauseService(pauseData)}>{pauseName}</PauseButton>
                </RightContainer>
            </MainFlexContainer>
        </DebugContainer>
    )
}

export default TableteHome